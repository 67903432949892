import {PostToAny, PostToPMIS} from "./service-http-post";
import {GetStorageRecord, SetStorageRecord} from "./service-locastorage";
import {LoginType} from "../types/general-types";
import {setLoginState} from "../redux/actions";
import {CheckIFCookieExist, GetCookiesJson} from "./service-cookies";
import {CmisWcAuthRecord, ServerHostPmisService} from "../constants/common";
import {initialLoginType} from "../types/initialize-types";
import Cookies from "js-cookie";


export const LoginUserServicePmis=async (postData:any,feedback:any)=>{
    let endpoint="/services/logs/mobile/login"
    let host = ServerHostPmisService
    await PostToAny(postData,host,endpoint,(response:any)=>{
        console.log("login submit response> ",response)
        feedback(response)
    })
}
export const LoginUser=async (postData:any,feedback:any)=>{
    let endpoint="/woodchipping/auth/login"
    await PostToPMIS(postData,endpoint,(response:any)=>{
        console.log("login submit response> ",response)
        feedback(response)
    })
}
export const RegisterUser=async (postData:any,feedback:any)=>{
    let endpoint="/user/new"
    await PostToPMIS(postData,endpoint,(response:any)=>{
        console.log("register submit response> ",response)
        feedback(response)
    })
}

export const GetCurrentLoginState=():LoginType=>{
    let out:LoginType = initialLoginType
    console.log("CheckIFCookieExist > ",CheckIFCookieExist(CmisWcAuthRecord))
    if(CheckIFCookieExist(CmisWcAuthRecord)){
        out  = GetCookiesJson(CmisWcAuthRecord) as LoginType
        console.log("CheckIFCookieExist out > ",out)
    }
    return out
}