import {WcLogChipping, WcLogSale} from "../../types/data-types-woodchipping";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import moment from "moment";
import React from "react";
import {GetDimensions} from "../../services/service-global";
import LinkButton from "./LinkButton";
import {initialWcLogChipping, initialWcLogSale} from "../../types/initialize-types";

const {width, height} = GetDimensions()
interface Props{
    Log? :WcLogChipping,
    LogSale?:WcLogSale
    onDelete?:any,
    onEdit?:any,
    onView?:any,
    position:number,
    totalLoad:number
    category:string
    refLabel:string
    quantityLabel:string
}

export default (props:Props)=>{

    let targetWidth = width
    if (width > 768) {
        targetWidth = 768
    }



    const RenderContainer=(ref:string,refLabel:string,volumeRef:any,volumeLabel:string,
                           DeliveryDate:string,Vehicle:string,record:any)=>{
        console.log("MYIYYYYY Delivery Date: ",DeliveryDate,record)
        return(
            <Container>
                <InnerRow>
                    <LabelRef>{refLabel}: </LabelRef>
                    <LabelLoadMass>{ref} </LabelLoadMass>
                </InnerRow>
                <InnerRow>
                    <LabelRef>{volumeLabel}: </LabelRef>
                    <LabelLoadMass> {volumeRef}</LabelLoadMass>
                </InnerRow>
                <InnerRow>
                    <LabelDeliveryDate>
                        {moment(record.DeliveryDate,"YYYY-MM-DD").format("DD-MMM-YYYY")}
                    </LabelDeliveryDate>
                    <LabelVehicle>
                        Veh: {Vehicle}
                    </LabelVehicle>
                </InnerRow>
                <InnerRow>
                    <LinkButton
                        title={"View-Detail"}
                        onClick={()=>props.onView(record,props.category)}
                        labelColor={Colors.brand.blue}
                    />
                    <LinkButton
                        title={"Delete Log"}
                        onClick={()=>props.onDelete(record,props.category)}
                        labelColor={Colors.brand.red}
                    />
                    <LinkButton
                        title={"Edit Log"}
                        onClick={()=>props.onEdit(record,props.category)}
                        labelColor={Colors.brand.orange}
                    />
                </InnerRow>

            </Container>
        )
    }

    console.log(" ------ SelectedLogs >",props.category)
    if(props.category==="chipping"){
        let log = props.Log || initialWcLogChipping
        return RenderContainer(log.WbRef,props.refLabel,log.LoadMass,props.quantityLabel,
            log.DeliveryDate,log.Vehicle,log)
    }else{
        let log = props.LogSale || initialWcLogSale
        console.log(props.category," ||%%%%%% SelectedLogs > ",log)
        return RenderContainer(log.DeliveryNote,props.refLabel,`${log.CubicSize} m3`,props.quantityLabel,
            log.DeliveryDate,log.Vehicle,log)
    }

}

const MyContainer = styled.div<{ width: number }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  min-height: 55px;
  padding: 5px;

`
const MyButton = styled.button<{ width: number, bgColor: string, labelColor: string }>`
  width: ${(props) => props.width}px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.labelColor};
  border: 1px solid white;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
`

const LabelVehicle=styled.label`
  font-weight: normal;
  font-size: 16px;
  color:${Colors.brand.gray}
`

const LabelDeliveryDate=styled.label`
  font-weight: normal;
  font-size: 16px;
  color:${Colors.brand.gray}
`
const LabelLoadMass=styled.label`
  font-weight: bold;
  font-size: 18px;
  color:${Colors.brand.blue}
`
const LabelRef=styled.label`
  font-weight: bold;
  font-size: 18px;
`
const InnerRow=styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  `
const Container=styled.div`
    width: 90%;
    display: flex;
    justify-content: flex-start;
  flex-direction: column;
  border: 1px solid ${Colors.brand.lightBlue};
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
    `