import React from "react";
import {makeStyles, TextField, withStyles} from "@material-ui/core";

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);
const innerStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth:"100%"
    },
    margin: {
        margin: theme.spacing(0),
        maxWidth:"100%",
        minWidth:"100%",
    },
    formControl: {
        margin: theme.spacing(3),
        borderColor:"gray",
        borderRadius:3,
        borderWidth:1
    },
}));

type Props={
    label:string,
    required:boolean,
    handleInput:any,
    styleIn:Object,
    value:any
}
export default (props:Props) => {
    let {label,required,handleInput, styleIn,value}=props;
    const classes = innerStyles();
    const handleChange = (event:any) => {
        console.log("InputBox handleInput2 > ",event.target.value,event);
        handleInput(event.target.value)
    };
    return (
        <CssTextField
            className={classes.margin}
            label={<span style={{fontSize:16}}> {label}</span>}
            variant="outlined"
            id="custom-css-outlined-input"
            name={label}
            required={required}
            onChange={handleChange}
            style={styleIn}
            value={value}
        />
    )
}
