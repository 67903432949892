import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(0),
        },
    },
}));

type Props={
    variant:any,
    color:any,
    label:any,
    type:any,
    onClick:any,
    styleIn:Object,
}
export default (props:Props) =>{
    let {variant,color,label,type,onClick,styleIn}=props;
    const classes = useStyles();

    return (
        <div className={classes.root} style={{minWidth:"100%"}}>
            <Button
                variant={variant}
                color={color}
                onClick={()=>onClick()}
                style={styleIn}
            >
                {label}
            </Button>

        </div>
    );
}
