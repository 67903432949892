import TopBar2 from "../../compoents/common/TopBar2";
import React, {useEffect, useState} from "react";
import Page from "../Page";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import {
    buildComboCompostMaker,
    buildComboVehicle,
    FetchDataConfig,
    GetDimensions,
    goTo
} from "../../services/service-global";
import {Project} from "../../types/model";
import ButtonList from "../../compoents/common/ButtonList";
import InputLabelForm from "../../compoents/common/InputLabelForm";
import ListKeyVal from "../../compoents/common/ListKeyVal";
import InfoBarSelectedProject from "../../compoents/common/InfoBarSelectedProject";
import NavBarBackCancel from "../../compoents/common/NavBarBackCancel";
import {ConfigData, WcLogChipping} from "../../types/data-types-woodchipping";
import {initialConfigData} from "../../types/initialize-types";
import {useSelector} from "react-redux";
import {GetCurrentLoginState} from "../../services/service-auth";
import BoxSelectAutoComplete from "../../compoents/common/BoxSelectAutoComplete";
import DateLabelForm from "../../compoents/common/DateLabelForm";
import moment from "moment";
import {AppName, DbName} from "../../constants/common";
import {PostToPMIS} from "../../services/service-http-post";
import {CrudInsertRecordAny} from "../../services/service-curd";
import {GetClientList, GetProjectList, LoadDataConfig} from "../../services/service-chipping-logs";

const {width, height} = GetDimensions()
export default (props: any) => {
    const state = useSelector((state: any) => state.core);
    const [Step, setStep] = useState(1)
    const [DataProject, setDataProject] = useState<Project[]>([])
    const [DataConfig, setDataConfig] = useState<ConfigData>(initialConfigData)
    const [SelectedProject, setSelectedProject] = useState("General Cleaning Services at City Buildings (Leased and Owned)")
    const [IsSync, setIsSync] = useState(false)
    const [LoginState, setLoginState] = useState(GetCurrentLoginState())
    const [InputVehicleRegNo, setInputVehicleRegNo] = useState("")
    const [InputDeliveryDate, setInputDeliveryDate] = useState("")
    const [InputWebRef, setInputWebRef] = useState("")
    const [InputCubicSize, setInputCubicSize] = useState("")
    const [InputLoadMass, setInputLoadMass] = useState("")
    const [InputQty, setInputQty] = useState("")
    const [InputDriver, setInputDriver] = useState("")
    const [InputClient,setInputClient]=useState("")
    const [InputDeliveryNote,setInputDeliveryNote]=useState("")
    const [InputCompostMaker,setInputCompostMaker]=useState("")

    let targetWidth = width
    if (width > 7) {
        targetWidth = 768
    }

    useEffect(() => {
        if (!IsSync) {
            //loadDataConfig().then(null)
            LoadDataConfig(LoginState.Org,LoginState.Username,setDataConfig).then(null)
            setIsSync(true)
        }
    })

    const loadDataConfig = async () => {
        await FetchDataConfig(LoginState.Org, LoginState.Username, (res: any) => {
            console.log("loadDataConfig result> ", res, " !!=> ", state.LoginState)
            let data = res as ConfigData
            setDataConfig(data)
        })
    }
    const onSelectClient = (pro: string) => {
        setInputClient(pro)
        setStep(2)
        console.log("::::onSelectClient > ", pro)
    }
    const onSelectProject = (pro: string) => {
        setSelectedProject(pro)
        setStep(3)
        console.log("::::onSelectProject > ", pro)
    }
    const onChangeVehicle = (value: any) => {
        console.log("::::onChangeVehicle > ", value)
        setInputVehicleRegNo(value)
    }
    const onChangeCompostMaker=(value:any)=>{
        setInputCompostMaker(value)
    }

   /* const buildComboVehicle = (): { key: string, value: any }[] => {
        let ls: { key: string, value: any }[] = [];
        for (let i in DataConfig.Vehicles) {
            let row = DataConfig.Vehicles[i];
            if (row.Org !== LoginState.Org) {
                continue
            }
            ls.push({
                value: row.RegNumber,
                key: row.RegNumber
            })
        }
        return ls
    }*/

    const buildComboDriver = (): { key: string, value: any }[] => {
        let ls: { key: string, value: any }[] = [];
        for (let i in DataConfig.Drivers) {
            let row = DataConfig.Drivers[i];
            if (row.Org !== LoginState.Org) {
                continue
            }
            ls.push({
                value: row.Name,
                key: row.Name
            })
        }
        return ls
    }

    const validationInput = (): boolean => {
        const alertMe = (inputField: string) => {
            alert(`Input validation fail, ${inputField} is required!`)
        }
        if (InputVehicleRegNo === "") {
            alertMe("Vehicle Registration number")
            return false
        } else if (InputDriver === "") {
            alertMe("Driver")
            return false
        } else if (InputDeliveryDate === "") {
            alertMe("Delivery Date")
            return false
        } else if (InputWebRef === "") {
            alertMe("Web Ref")
            return false
        } else if (InputCubicSize === "") {
            alertMe("Cubic Size")
            return false
        } else if (InputLoadMass === "") {
            alertMe("Load Mass")
            return false
        } else if (InputQty === "") {
            alertMe("Quantity")
            return false
        }
        return true
    }
    const onClickContinue = () => {
        if (validationInput()) {
            setStep(4)
        } else {
            return
        }
        /**
         * todo validation inputs
         */
    }
    const onSubmit = async () => {
        let record:WcLogChipping={
            AppName,
            Org:LoginState.Org,
            Client:InputClient,
            Project:SelectedProject,
            CubicSize:parseFloat(InputCubicSize),
            DeliveryDate:InputDeliveryDate,
            DeliveryTime:moment().format("YYYY-MM-DD HH:mm:SS"),
            Driver:InputDriver,
            LoadMass:parseFloat(InputLoadMass),
            Quantity:parseInt(InputQty),
            Vehicle:InputVehicleRegNo,
            WbRef:InputWebRef,
            DeliveryNote:InputDeliveryNote,
            CompostMaker:InputCompostMaker,
            Date:moment().format("YYYY-MM-DD"),
            Time:moment().format("HH:mm:SS"),
            OrgDateTime:moment().format("YYYY-MM-DD HH:mm:SS"),
            Status:"active"
        }

        let endpoint = "/data/insert"
        let postData = {
            AppName,
            DbName,
            Data:record,
            Org:LoginState.Org,
            Table:"WcLogChipping"
        }
        console.log("Submit ):(--> send> ",postData)
        await PostToPMIS(postData,endpoint,(res:any)=>{
            console.log("Submit ):(--> response> ",res)
            alert("Thank you , log chipping submitted")
            goTo("/home")
        })
    }
    const onBack = () => {
        if (Step > 1) {
            let step = Step - 1
            setStep(step)
        }else if(Step===1){
            goTo("/home")
        }
    }
    const onCancel = () => {
        if (!window.confirm("Are you sure to cancel this new chipping?")) {
            return
        }
        goTo("home")
    }

    const getSummaryKeyValue = (): { key: string, value: any }[] => {
        return [
            {
                key: "Client",
                value: InputClient
            },
            {
                key: "Project",
                value: SelectedProject
            },
            {
                key: "Vehicle",
                value: InputVehicleRegNo
            },
            {
                key: "Driver",
                value: InputDriver
            },
            {
                key: "Delivery Date",
                value: moment(InputDeliveryDate,"YYYY-MM-DD").format("DD MMM YYYY")
            },
            {
                key: "Web Ref",
                value: InputWebRef
            },
            {
                key: "Cubic Size",
                value: InputCubicSize
            },
            {
                key: "Load Mass",
                value: InputLoadMass
            },
            {
                key: "Quantity",
                value: InputQty
            }
        ]
    }

    const getProjectList = (): string[] => {
        return GetProjectList(DataConfig,LoginState.Org,InputClient)
        /*let ls: string[] = [];
        for (let i in DataConfig.Projects) {
            let row = DataConfig.Projects[i]
            if (row.Org !== LoginState.Org) {
               continue
            }
            if(row.Client!==InputClient){
                continue
            }
            ls.push(row.Name)
        }
        return ls*/
    }
    const getClientList=():string[]=>{
        return GetClientList(DataConfig,LoginState.Org)
        /*let ls: string[] = [];
        for (let i in DataConfig.Clients) {
            let row = DataConfig.Clients[i]
            if (row.Org === LoginState.Org) {
                ls.push(row.Name)
            }
        }
        return ls*/
    }
    const RenderStep4 = () => {
        return (
            <>
                <Label2>
                    Following are your input summary provided,
                    please make sure that it is correct before continue.
                </Label2>
                <InfoBarSelectedProject project={SelectedProject}/>
                <ListKeyVal list={getSummaryKeyValue()}/>

                <MyContainer width={targetWidth - 60}>
                    <MyButton
                        width={(targetWidth - 60)}
                        bgColor={Colors.brand.green}
                        labelColor={Colors.brand.white}
                        onClick={onSubmit}
                    >Submit</MyButton>
                </MyContainer>

            </>
        )
    }
    const RenderStep3 = () => {
        return (
            <>
                <Label2>
                    Input your log information based on your delivery note.
                </Label2>
                <InfoBarSelectedProject project={SelectedProject}/>

                <DateLabelForm
                    label={"Delivery Date"}
                    onChange={(val: any) => setInputDeliveryDate(val)}
                    labelColor={Colors.brand.gray}
                />


                <InnerBox>
                    <BoxSelectAutoComplete
                        label={"Vehicle Reg. No."}
                        handleInput2={onChangeVehicle}
                        data={buildComboVehicle(DataConfig.Vehicles,LoginState.Org)}

                    />
                </InnerBox>
                <InnerBox>
                    <BoxSelectAutoComplete
                        label={"Driver"}
                        handleInput2={setInputDriver}
                        data={buildComboDriver()}

                    />
                </InnerBox>

                <InnerBox>
                    <BoxSelectAutoComplete
                        label={"Compost Maker"}
                        handleInput2={onChangeCompostMaker}
                        data={buildComboCompostMaker(DataConfig.Clients,LoginState.Org)}

                    />
                </InnerBox>

                <InputLabelForm
                    label={"Weigh Bridge Ref"}
                    onChange={(val: any) => setInputWebRef(val)}
                    labelColor={Colors.brand.gray}

                />
                <InputLabelForm
                    label={"Compost Maker Delivery Note"}
                    onChange={(val: any) => setInputDeliveryNote(val)}
                    labelColor={Colors.brand.gray}

                />
                <InputLabelForm
                    label={"Cubic Size"}
                    onChange={(val: any) => setInputCubicSize(val)}
                    labelColor={Colors.brand.gray}
                    type={"numeric"}
                />
                <InputLabelForm
                    label={"Load Mass"}
                    onChange={(val: any) => setInputLoadMass(val)}
                    labelColor={Colors.brand.gray}
                    type={"numeric"}
                />
                <InputLabelForm
                    label={"Quantity"}
                    onChange={(val: any) => setInputQty(val)}
                    labelColor={Colors.brand.gray}
                    type={"numeric"}
                />

                <MyContainer width={targetWidth - 60}>
                    <MyButton
                        width={(targetWidth) - 60}
                        bgColor={Colors.brand.gray}
                        labelColor={Colors.brand.white}
                        onClick={onClickContinue}
                    >Continue</MyButton>
                </MyContainer>
            </>
        )
    }

    const RenderStep1= () => {
        return (
            <>
                <Label2>
                    Please select your client from the list below,
                    and continue to input form
                </Label2>

                <ButtonList
                    options={getClientList()}
                    onPress={onSelectClient}
                    selectedProject={InputClient}
                />
            </>
        )
    }
    const RenderStep2 = () => {
        return (
            <>
                <Label2>
                    Please select your target project from the list below,
                    and continue to input form
                </Label2>

                <ButtonList
                    options={getProjectList()}
                    onPress={onSelectProject}
                    selectedProject={SelectedProject}
                />
            </>
        )
    }

    const SwitchView = () => {
        switch (Step) {
            case 1:
                return RenderStep1()
            case 2:
                return RenderStep2()
            case 3:
                return RenderStep3()
            case 4:
                return RenderStep4()
            default:
                return RenderStep1()
        }
    }
    return (
        <Page noMenu={false}>
            <TopBar2 title={"New Wood Chipping Log"}/>
            <Container>
                <NavBarBackCancel
                    onBack={onBack}
                    onCancel={onCancel}
                />
                <Label1>
                    Step: {Step}/{3}
                </Label1>
                {SwitchView()}
            </Container>
        </Page>
    )
}

const InnerBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

const MyContainer = styled.div<{ width: number }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  min-height: 55px;
  padding: 5px;

`
const MyButton = styled.button<{ width: number, bgColor: string, labelColor: string }>`
  width: ${(props) => props.width}px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.labelColor};
  border: 1px solid white;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
`
const ContainerSubmit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`
const ContainerAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 100px;
  margin-top: 10px;
`

const Label2 = styled.label`
  font-size: 22px;
  margin-top: 10px;
  text-align: left;
  font-weight: bold;
  width: 100%;
`
const Label1 = styled.label`
  font-size: 22px;
  font-weight: bold;
  color: ${Colors.brand.blue};
  margin-top: 10px;
  align-self: flex-start;
`
const Container = styled.div`
  background-color: ${Colors.brand.white};
  width: ${width - 40}px;
  min-height: ${(props) => height + "px"};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 768px;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`

