import React, {useEffect, useState} from "react";
import Page from "../Page";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import {
    FetchDashboardChippingMin,
    GetDimensions,
    getListOfDatesBasedOnCurrentWithAddOrMinusOption,
    goTo
} from "../../services/service-global";
import TopBar2 from "../../compoents/common/TopBar2";
import DashboardLine1 from "../../compoents/common/DashboardLine1";
import moment from "moment";
import CircleActionButton from "../../compoents/common/CircleActionButton";
import {GetCurrentLoginState} from "../../services/service-auth";
import {useSelector} from "react-redux";
import {initialMinDashboard} from "../../types/initialize-types";
import {MinDashboard} from "../../types/data-types-woodchipping";
import {SetStorageRecord} from "../../services/service-locastorage";
import {
    SelectedDashboardLogsCategory,
    SelectedDashboardLogsDetail,
    SelectedDashboardLogsDetailQuantityLabel,
    SelectedDashboardLogsDetailRefLabel,
    SelectedDashboardLogsDetailTitle,
    SelectedDashboardLogsTitle
} from "../../constants/common";
import DateSelectorArray from "../../compoents/common/DateSelectorArray";

const {width, height} = GetDimensions()

const formatMe = (d: moment.Moment) => {
    return d.format('DD-MMM-YYYY')
}
export default (props: any) => {
    const [todayDate,setTodayDate]=useState(  moment().format("DD-MMM-YYYY"))
    const state = useSelector((state: any) => state.core);
    const [IsSync, setIsSync] = useState(false)
    const [LoginState, setLoginState] = useState(GetCurrentLoginState())
    const [InfoDashboardChippingMin, setInfoDashboardChippingMin] = useState(initialMinDashboard)
    const [TargetDates,setTargetDates]=useState<string[]>([])
    const [SelectedTargetDate,setSelectedTargetDate]=useState(moment().format("YYYY-MM-DD"));


    // Get the current date
    const currentDate = moment();

// Get the beginning of the month
    const startOfMonth = currentDate.clone().startOf('month');

// Get the end of the month
    const endOfMonth = currentDate.clone().endOf('month');

    const imageLink = "https://cdn.24.co.za/files/Cms/General/d/8585/f81e4ab9a046427299dff8e6dccc00cb.jpg"

    let targetWidth = width;
    if (width > 768) {
        targetWidth = 768
    }

    const getStartDate=():string=>{
        const currDate = moment(SelectedTargetDate,"YYYY-MM-DD")
        const startOfMonth = currDate.clone().startOf('month');
        return startOfMonth.format("DD-MMM-YYYY")
    }
    const getEndDate=():string=>{
        const currDate = moment(SelectedTargetDate,"YYYY-MM-DD")
        const startOfMonth = currDate.clone().endOf('month');
        return startOfMonth.format("DD-MMM-YYYY")
    }

    useEffect(() => {
        if (!IsSync) {
            loadDashboardChippingMin(SelectedTargetDate).then(null)
            loadTargetDates()
            setIsSync(true)
        }
    })

    const loadTargetDates=()=>{
        let dates = getListOfDatesBasedOnCurrentWithAddOrMinusOption("-",11)
        setTargetDates(dates)
        console.log("Target Dates: ",dates,SelectedTargetDate)

    }
    const loadDashboardChippingMin = async (targetDate:string) => {
        FetchDashboardChippingMin(LoginState.Org,targetDate, (res: any) => {
            let info = res as MinDashboard
            setInfoDashboardChippingMin(info)
            console.log("loadDashboardChippingMin > ", res)
        }).then(null)
    }
    const onClickSale = () => {
        goTo("new-log-sale")
    }
    const onClickChipping = () => {
        goTo("new-log-chipping")
    }
    const onClickImportCsvChipping=()=>{
        goTo("new-log-chipping-csv")
    }

    const onSelectTargetDate=async (date:string)=>{
        setSelectedTargetDate(date);
        loadDashboardChippingMin(date).then(null)
        setTodayDate(moment(date,"YYYY-MM-DD").format("DD-MMM-YYYY"))
    }
    const dashboardClick = async (title: string,category:string,detailTitle:string,detailRefLabel:string,detailQuantityLabel:string) => {
        await SetStorageRecord(SelectedDashboardLogsCategory, category)

        await SetStorageRecord(SelectedDashboardLogsDetailTitle,detailTitle);
        await SetStorageRecord(SelectedDashboardLogsDetailRefLabel,detailRefLabel);
        await SetStorageRecord(SelectedDashboardLogsDetailQuantityLabel,detailQuantityLabel);

        if(category==="chipping") {
            if (title === "Daily") {
                await SetStorageRecord(SelectedDashboardLogsTitle, "Daily")
                await SetStorageRecord(SelectedDashboardLogsDetail, JSON.stringify(InfoDashboardChippingMin.Daily.Logs))
            } else if (title === "Monthly") {
                await SetStorageRecord(SelectedDashboardLogsTitle, "Monthly")
                await SetStorageRecord(SelectedDashboardLogsDetail, JSON.stringify(InfoDashboardChippingMin.Monthly.Logs))
            }
        }else{
            if (title === "Daily") {
                await SetStorageRecord(SelectedDashboardLogsTitle, "Daily")
                await SetStorageRecord(SelectedDashboardLogsDetail, JSON.stringify(InfoDashboardChippingMin.DailySale.Logs))
            } else if (title === "Monthly") {
                await SetStorageRecord(SelectedDashboardLogsTitle, "Monthly")
                await SetStorageRecord(SelectedDashboardLogsDetail, JSON.stringify(InfoDashboardChippingMin.MonthlySale.Logs))
            }
        }

        goTo("/home-detail-logs")
    }

    const onLogout = ()=>{
        /**
         * todo clear the cache
         */
        goTo("/")
    }

    return (
        <Page noMenu={false}>
            <TopBar2 title={"Cemex Wood Chipping App"}/>
            <Container>
                <DateSelectorArray
                    selectedDate={SelectedTargetDate}
                    data={TargetDates}
                    bgColor={Colors.brand.gray}
                    labelColor={Colors.brand.white}
                    bgColorSelected={Colors.brand.green}
                    labelColorSelected={Colors.brand.white}
                    format={"MMM YY"}
                    onPress={onSelectTargetDate}
                />
                <Label1>
                    Statistics
                </Label1>
                <DashboardLine1
                    title={"Chapping Daily Load Quantity"}
                    startDate={todayDate}
                    TotalService={InfoDashboardChippingMin.Daily.Quantity}
                    type={"Daily"}
                    category={"chipping"}
                    onClick={dashboardClick}
                    detailRefLabel={"WB Ref"}
                    detailQuantityLabel={"Load Mass / m3"}
                    targetDate={SelectedTargetDate}
                />
                <DashboardLine1
                    title={"Chapping Monthly Load Quantity"}
                    startDate={getStartDate() + " to " + getEndDate()}
                    TotalService={InfoDashboardChippingMin.Monthly.Quantity}
                    type={"Monthly"}
                    category={"chipping"}
                    onClick={dashboardClick}

                    detailRefLabel={"WB Ref"}
                    detailQuantityLabel={"Load Mass / m3"}
                    targetDate={SelectedTargetDate}
                />

                <Separator />



                <Label1>
                   Actions
                </Label1>
                <Label2>
                    for new service logs on both side,
                    please make use of below bottom command.

                </Label2>
                <MyContainer width={targetWidth - 60}>

                    <MyButton
                        width={(targetWidth / 2) - 35}
                        bgColor={Colors.brand.green}
                        labelColor={Colors.brand.white}
                        onClick={onClickChipping}
                    >Log Service</MyButton>

                    {LoginState.Role==="super"&&
                        <MyButton
                            width={(targetWidth / 2) - 35}
                            bgColor={Colors.brand.lightGray}
                            labelColor={Colors.brand.white}
                            onClick={onClickImportCsvChipping}
                        >Import Csv Log Service</MyButton>
                    }
                </MyContainer>


                <ContainerImage
                    src={imageLink}
                    width={targetWidth}
                />

                <LogoutLink onClick={onLogout}>Logout</LogoutLink>
            </Container>
        </Page>
    )
}

const LogoutLink = styled.button`
    color:blue;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  height: 45px;
`
const Separator=styled.div`
    width: 95%;
    border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${Colors.brand.blue};
  margin-top: 20px;
  
`
const MyContainer = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid white;
  min-height: 55px;
  padding: 5px;

`
const MyButton = styled.button<{ width: number, bgColor: string, labelColor: string }>`
  width: ${(props) => props.width}px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.labelColor};
  border: 1px solid white;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
`

const Label2 = styled.label`
  color: #282c34;
`
const ContainerImage = styled.img<{ width: number }>`
  width: ${(props) => props.width - 40}px;
  height: ${(props) => props.width - 40}px;
  max-height: 768px;
  margin-top: 20px;
  border-radius: 5px;
`
const Blank1 = styled.div<{ size: number }>`
  width: 50px;
  height: ${(props) => props.size / 2}px;
  border-right-width: 0.5px;
  border-right-style: solid;
  border-right-color: ${Colors.brand.lightGray};
  margin-right: 10px;
  border-radius: ${(props) => props.size / 2}px;;
`

const Label1 = styled.label`
  font-size: 22px;
  font-weight: bold;
  color: ${Colors.brand.blue};
  margin-top: 20px;
  align-self: flex-start;
`
const Container = styled.div`
  background-color: ${Colors.brand.white};
  width: ${width - 40}px;
  min-height: ${(props) => height + "px"};
  display: flex;
  flex-direction: column;
  max-width: 768px;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`

