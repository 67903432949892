import React, {useState} from "react";

import {SiteType} from "../../../types/general-types";


import Colors from "../../../constants/Colors";
import {GetDimensions} from "../../../services/service-global";
import styled from "styled-components";


type Props = {
    onSelect: any,
    setStep: any,
    SelectedCompany: string,
    SelectedClient: string,
    SelectedItem: string,
    DataSites: SiteType[]
}
const {width, height} = GetDimensions()
export default (props: Props) => {

    console.log("))DataSites received: ",props.DataSites.length)
    const getSiteList = (): SiteType[] => {
        return props.DataSites
        let infoData: { [index: string]: SiteType } = {}
        let company = props.SelectedCompany
        let client = props.SelectedClient;
        let project = props.SelectedItem;
        let ls: SiteType[] = []
        for (let i in props.DataSites) {
            let row = props.DataSites[i]

            if (row.org !== company) {
                continue
            }
            if (row.client !== client) {
                continue
            }
            if (row.item !== project) {
                continue
            }
            infoData[row.site] = row
        }
        for (let i in infoData) {
            let row = infoData[i]
            ls.push(row)
        }

        return ls
    }

    return (

        <Container>
            <label>Total sites ({getSiteList().length})</label>
            {getSiteList().map((row, index) => {
                return (
                    <TouchableHighlight
                        onClick={() => props.onSelect(row)}
                        key={index}
                    >
                        <CardStyleContainer>
                            <CardContent>
                                <ViewBox>
                                    <ViewBoxLabel>
                                        <label>{row.site}</label>
                                        <label>{row.suburb}</label>
                                    </ViewBoxLabel>
                                    <ViewBoxQuantity>
                                        <ViewQuantity>{row.quantity}</ViewQuantity>
                                    </ViewBoxQuantity>
                                </ViewBox>
                            </CardContent>
                        </CardStyleContainer>
                    </TouchableHighlight>

                )
            })}
        </Container>
    )
}

const TouchableHighlight=styled.div`
    cursor: pointer;
`
const ViewBoxQuantity = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 60px;
  height: 30px
`
const ViewQuantity = styled.div`
  color: ${Colors.brand.green};
  font-weight: bold;
  font-size: 18px
`

const ViewBoxLabel = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-start;
`
const ViewBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const CardContent = styled.div`

`
const CardStyleContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  border-color: green;
  margin-top: 10px;
`
const Container = styled.div`
  padding: 10px;
`


