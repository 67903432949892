import Colors from "../../../constants/Colors";
// @ts-ignore
//import Icon from 'react-native-vector-icons/FontAwesome';

import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {GetDimensions} from "../../../services/service-global";
import React from "react";
import styled from "styled-components";

type Props = {
    title: string,
    onPress: any,
    color?: string,
    backgroundColor?: string,
}
const {width, height} = GetDimensions()
export default (props: Props) => {
    const Button = styled.button`
      background-color: ${props.backgroundColor};
      border-style: solid;
      border-width: 0;
      border-radius: 50px;
      /*borderColor:Colors.brand.backgroundColor;*/
      height: 100px;
      width: 100px;
      margin-top: 20px;
      margin-left: ${width - 70};
      justify-content: center;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    `;
    const BtnText = styled.text`
      color: ${Colors.brand.lightRed};
      text-align: center;
    `

    return (
        <Button
            onClick={() => props.onPress()}
        >
            <Icon icon={faPlus} color={Colors.brand.white} size={"lg"}/>
            {/*<BtnText>{props.title}</BtnText>*/}
        </Button>
    )
}