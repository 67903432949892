import TopBar2 from "../compoents/common/TopBar2";
import React from "react";
import Page from "./Page";
import styled from "styled-components";
import Colors from "../constants/Colors";
import {GetDimensions} from "../services/service-global";

const {width, height} = GetDimensions()
export default ()=>{

    return (
        <Page noMenu={false}>
            <TopBar2 title={"Service Logs & Wood Chipping"}/>
            <Container>

            </Container>
        </Page>
    )

}

const Container = styled.div`
  background-color: ${Colors.brand.white};
  width: ${width - 40}px;
  min-height: ${(props) => height + "px"};
  display: flex;
  flex-direction: column;
  max-width: 768px;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`
