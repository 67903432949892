import moment from "moment";
import styled from "styled-components";
import Colors from "../../constants/Colors";


export default ()=>{
    const year = moment().year()
    return(
        <Container>
            <Label1>
                {year}&copy; BizMinder (Pty) Ltd V2.0.1
            </Label1>
        </Container>
    )
}

const Label1 = styled.label`
    color: ${Colors.brand.white}
    `
const Container = styled.div`
      bottom: 10px;
  position: absolute;
  z-index: 100;
  margin-top: 10px;
    `