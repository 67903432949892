import styled from "styled-components";
import PageTitle from "../../compoents/common/PageTitle";
import Colors from "../../constants/Colors";
import React, {useEffect, useState} from "react";
import {WcLogChipping, WcLogSale} from "../../types/data-types-woodchipping";
import {GetStorageRecord, SetStorageRecord} from "../../services/service-locastorage";
import {
    SelectedDashboardLogsCategory,
    SelectedDashboardLogsDetail,
    SelectedDashboardLogsDetailEdit,
    SelectedDashboardLogsDetailQuantityLabel,
    SelectedDashboardLogsDetailRefLabel,
    SelectedDashboardLogsDetailTitle,
    SelectedDashboardLogsTitle
} from "../../constants/common";
import NavBarBackCancel from "../../compoents/common/NavBarBackCancel";
import {GetDimensions, goTo} from "../../services/service-global";
import DetailLogsBox from "../../compoents/common/DetailLogsBox";
import TopBar2 from "../../compoents/common/TopBar2";
import {DeleteChippingLog} from "../../services/service-chipping-logs";


export default () => {
    const [Sync, setSync] = useState(false)
    const [DetailType, setDetailType] = useState("")
    const [DetailCategory, setDetailCategory] = useState("")
    const [Logs, setLogs] = useState<WcLogChipping[]>([])
    const [LogsSale, setLogsSale] = useState<WcLogSale[]>([])

    const [detailTitle, setDetailTitle] = useState("")
    const [detailRefLabel, setDetailRefLabel] = useState("");
    const [detailQuantityLabel, setDetailQuantityLabel] = useState("");


    useEffect(() => {
        if (!Sync) {
            loadLogs().then(null)
            setSync(true)
        }
    })

    const loadLogs = async () => {
        let logsTitle = await GetStorageRecord(SelectedDashboardLogsTitle);
        let logsCategory = await GetStorageRecord(SelectedDashboardLogsCategory);
        setDetailCategory(logsCategory)
        setDetailType(logsTitle)

        /**
         * load additional info
         */
        let detailTitle = await GetStorageRecord(SelectedDashboardLogsDetailTitle);
        let detailRefLabel = await GetStorageRecord(SelectedDashboardLogsDetailRefLabel);
        let detailQuantityLabel = await GetStorageRecord(SelectedDashboardLogsDetailQuantityLabel);
        setDetailTitle(detailTitle)
        setDetailRefLabel(detailRefLabel)
        setDetailQuantityLabel(detailQuantityLabel);


        let logsDetailString = await GetStorageRecord(SelectedDashboardLogsDetail)
        let logsDetail = JSON.parse(logsDetailString)

        if (logsCategory === "chipping") {
            let logs = logsDetail as WcLogChipping[]
            setDetailRefLabel("WB Ref")
            setLogs(logs)
        } else if (logsCategory === "sale"){
            let logs = logsDetail as WcLogSale[]
            setDetailRefLabel("Delivery Note")
            setLogsSale(logs)
        }
    }

    const onBack = () => {
        goTo("/home")
    }
    const onCancel = () => {
        goTo("/home")
    }

    const editLogRequest = async (logIn: WcLogChipping,category:string) => {
        await SetStorageRecord(SelectedDashboardLogsDetailEdit, logIn)
        goTo("/home-detail-log-edit")
    }
    const deleteLogRequest = async (recIn: WcLogChipping,category:string) => {
        if (!window.confirm(`Are you sure that you want to delete this logs with WB-Ref: ${recIn.WbRef}`)) {
            return
        }

        let table = "chipping";
        if(category==="sale"){
            table = "sale"
        }

        console.log("DeleteChippingLog send> ", category)
        await DeleteChippingLog("delete",table, recIn, (res: any) => {
            console.log("DeleteChippingLog result> ", res)
            goTo("/home")
        })

    }


    return (
        <Container>
            <TopBar2 title={"Cemex Woodchipping App"}/>
            <NavBarBackCancel
                onBack={onBack}
                onCancel={onCancel}
            />
            <PageTitle title={`${detailTitle}`} color={Colors.brand.blue}/>
            <LabelCounter>{/*Count logs: {Logs.length}*/}</LabelCounter>

            {DetailCategory === "chipping" ? <>
                    {Logs.map((row, index) => {

                        return (
                            <DetailLogsBox
                                Log={row}
                                position={index + 1}
                                totalLoad={Logs.length}
                                onEdit={editLogRequest}
                                onDelete={deleteLogRequest}
                                category={"chipping"}
                                refLabel={detailRefLabel}
                                quantityLabel={detailQuantityLabel}
                            />
                        )
                    })}
                </> :
                <>
                    {LogsSale.map((row, index) => {

                        return (
                            <DetailLogsBox
                                LogSale={row}
                                position={index + 1}
                                totalLoad={Logs.length}
                                onEdit={editLogRequest}
                                onDelete={deleteLogRequest}
                                category={"sale"}
                                refLabel={detailRefLabel}
                                quantityLabel={detailQuantityLabel}
                            />
                        )
                    })}
                </>
            }
        </Container>
    )
}


const LabelCounter = styled.label`
  font-weight: bold;
  font-size: 18px;

`
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;

`