import Colors from "../../../constants/Colors";
import {GetDimensions} from "../../../services/service-global";
import React from "react";
import styled from "styled-components";

type Props = {
    Planned: number,
    Serviced: number
}

const {width, height} = GetDimensions()
export default (props: Props) => {
    return (
        <Container>
            <Box>
                <InnerBoxTop>
                    <Label>Planned Site</Label>
                </InnerBoxTop>
                <div style={innerBoxDown}>
                    <Value>{props.Planned}</Value>
                </div>
            </Box>
            <Box>
                <InnerBoxTop>
                    <Label>Actual Site</Label>
                </InnerBoxTop>
                <div style={innerBoxDown}>
                    <Value>{props.Serviced}</Value>
                </div>
            </Box>
        </Container>
    )
}

const Box = styled.div`
  background-color: ${Colors.brand.background};
  border-radius: 5px;
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Container = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
`
const InnerBoxTop = styled.div`
  border-bottom-width: 0;
  border-bottom-color: ${Colors.brand.white};
  border-radius: 10px;
`
const Label = styled.div`
  color: ${Colors.brand.blue};
  text-align: center;
  font-weight: bold;
  font-size: 16px;
`
const Value = styled.div`
  font-weight: bold;
  text-align: center;
  height: 25px;
  width: 30px;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding-top: 5px;
  margin-top: 3px;
`
const innerBoxDown: React.CSSProperties = {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        height: 35,
    },
    row: React.CSSProperties = {
        flexDirection: "row",
        justifyContent: "space-between",
        borderStyle: "solid",
        borderColor: Colors.brand.lightRed,
        borderRadius: 5,
        height: 25,
        borderWidth: 1,
        marginTop: 5,
        paddingLeft: 10,
        paddingRight: 10
    };


