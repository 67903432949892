import {
    ClientType,
    CompanyType,
    ItemType, MiniDashInfoResult,
    MiniDashInfoType,
    SiteInfo,
    SiteType,
    VehicleType
} from "../types/general-types";
import {PostToPMIS, PostToPmisServicing} from "./service-http-post";
import {initialMiniDashInfoResult, initialMiniDashInfoType} from "../types/initialize-types";


export const FetchMiniDash = async (params: {
    org: string,
    client: string,
    item: string,
    targetSite: string,
    periodType: string
}, feedback: any) => {
    let endpoint = "/services/logs/mobile/minidashboard"
    await PostToPmisServicing(params, endpoint, (response: any) => {
        type Inner = {
            RESULT: any
        }
        let info: Inner = response as Inner
        if(!info){
            feedback(initialMiniDashInfoResult)
            return
        }
        let info2 = initialMiniDashInfoResult
        if(info.RESULT!==null){
            info2 = info.RESULT as MiniDashInfoResult
        }
        if (!info2.Record) {
            info2.Record = initialMiniDashInfoType
        }
        feedback(info2)
    })

}

export const ProcessSubmitLog = async (dataPost: any, feedback: any) => {
    let endpoint = "/services/logs/bulk-insert"
    await PostToPmisServicing(dataPost, endpoint, (response: any) => {
        feedback(response)
    })
}
export const FetchVehicle = async (company: string, feedback: any) => {
    let endpoint = "/common/entity/ServiceLogsVehicle/list"
    await PostToPmisServicing({}, endpoint, (response: any) => {
        type Inner = {
            RESULT: any[]
        }
        let ls: VehicleType[] = [];
        let info: Inner = response as Inner
        for (let i in info.RESULT) {
            let row = info.RESULT[i];
            console.log("XXXXXXX----> ", row)
            let org = "";
            if (typeof row["org"] !== "undefined") {
                org = row["org"]
            }

            console.log("(::)-> ", org, "==", company)
            if (org !== company) {
                continue
            }
            let rec = row as VehicleType
            ls.push(rec)
        }
        feedback(ls)
    })

}


export const FetchSites = async (company: string, client: string, project: string, feedback: any) => {
    let endpoint = "/common/entity/SettingInvoiceServiceAddonSite/list"
    await PostToPmisServicing({}, endpoint, (response: any) => {
        type Inner = {
            RESULT: any[]
        }
        console.log("///Sites > 2", response, " | company: ", company, " | client: ", client)
        let ls: SiteType[] = [];
        let info: Inner = response as Inner
        for (let i in info.RESULT) {
            let row = info.RESULT[i];
            //console.log("///Sites > 3",row)
            let name = "";
            let org = "";
            let client = "";
            let item = "";
            if (typeof row["org"] !== "undefined") {
                org = row["org"]
                name = row["name"]
                client = row["client"]
                item = row["item"]
            }
            if (org !== company) {
                continue
            }

            if (client !== client) {
                continue
            }
            if (item !== project) {
                continue
            }
            let rec = row as SiteType
            ls.push(rec)
        }
        feedback(ls)
    })

}
export const FetchProjects = async (company: string, client: string, feedback: any) => {
    let endpoint = "/common/entity/SettingInvoiceServiceItem/list"
    await PostToPmisServicing({}, endpoint, (response: any) => {
        type Inner = {
            RESULT: any[]
        }
        let ls: ItemType[] = [];
        let info: Inner = response as Inner
        for (let i in info.RESULT) {
            let row = info.RESULT[i];
            let name = "";
            let org = "";
            let client = "";
            if (typeof row["org"] !== "undefined") {
                org = row["org"]
                name = row["name"]
                client = row["client"]
            }
            if (org !== company) {
                continue
            }
            if (client !== client) {
                continue
            }

            let rec: ItemType = {
                Name: name,
                Org: org,
                Client: client
            }
            ls.push(rec)
        }
        feedback(ls)
    })

}
export const FetchClients = async (company: string, feedback: any) => {
    let endpoint = "/common/entity/SettingInvoiceServiceDebtors/list"
    await PostToPmisServicing({}, endpoint, (response: any) => {
        type Inner = {
            RESULT: any[]
        }
        let ls: ClientType[] = [];
        let info: Inner = response as Inner
        for (let i in info.RESULT) {
            let row = info.RESULT[i];
            let name = "";
            let org = "";
            if (typeof row["org"] !== "undefined") {
                org = row["org"]
                name = row["name"]
            }
            if (org !== company) {
                continue
            }
            let client: ClientType = {
                Name: name,
                Org: org
            }
            ls.push(client)
        }
        feedback(ls)
    })

}
export const FetchCompanies = async (feedback: any) => {
    let postData = {}
    let ls: CompanyType[] = []
    let endpoint = "/common/entity/companies/list"
    await PostToPmisServicing(postData, endpoint, (response: any) => {
        type Inner = {
            RESULT: any[]
        }
        let ls: CompanyType[] = [];
        let info: Inner = response as Inner
        for (let i in info.RESULT) {
            let row = info.RESULT[i];
            let name = "";
            let num = "";
            if (typeof row["customernumber"] !== "undefined") {
                num = row["customernumber"]
                name = row["name"]
            }
            let comp: CompanyType = {
                Name: name,
                CustomerNumber: num
            }
            ls.push(comp)
        }
        feedback(ls)
    })
    return ls
}