import {FormatMoneySouthAfrica2} from "../../../services/formating-number";
import Colors from "../../../constants/Colors";
import React from "react";
import {GetDimensions} from "../../../services/service-global";
import styled from "styled-components";

type Props = {
    Infos: { label: string, value: any, labelColor: string, bgColor: string }[]
}

const {width, height} = GetDimensions()
export default (props: Props) => {
    const Container = styled.div` 
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
    `
    const Box = styled.div<{borderColor:string}>` 
            border-style: solid;
            border-width: 0.51px;
            width: 30%;
            min-height: 60px;
            border-radius: 5px;
            background-color: ${Colors.brand.white};
            border-color: ${(props)=>props.borderColor};
      
        `
    const BoxInnerDown =  styled.div`
            min-height: 20px;
            justify-content: center;
            padding-bottom: 10px;
    `
    const  BoxInnerDownLabel = styled.div`
            text-align: center;
            font-weight: bold;
        `
    const  BoxInnerTop = styled.div`
            min-height: 40px;
            justify-content: center;
        `
    const BoxInnerTopLabel = styled.div`
            text-align: center;
            font-weight: bold;
            font-size: 20px
        `

    return (
        <Container>
            {props.Infos.map(row => {
                return (
                    <Box borderColor={ row.labelColor}>
                        <BoxInnerTop>
                            <BoxInnerTopLabel>{FormatMoneySouthAfrica2(row.value)}</BoxInnerTopLabel>
                        </BoxInnerTop>
                        <BoxInnerDown style={{
                            borderColor: row.bgColor,
                            borderWidth: 0
                        }}>
                            <BoxInnerDownLabel style={{
                                color: row.labelColor,
                            }}>{row.label}</BoxInnerDownLabel>
                        </BoxInnerDown>
                    </Box>
                )
            })}
        </Container>
    )
}