

export const ServerHostPmisService  = "https://cloudcalls.easipath.com/backend-pmis/api"
export const CmisWcAuthRecord = "CMIS-WC-AUTH-RECORD";

export const AppName="pmis";
export const DbName="pmis"

export const SelectedDashboardLogsTitle="SelectedDashboardLogsTitle";
export const SelectedDashboardLogsCategory="SelectedDashboardLogsCategory";
export const SelectedDashboardLogsDetail="SelectedDashboardLogsDetail";

export const SelectedDashboardLogsDetailEdit="SelectedDashboardLogsDetailEdit";

export const SelectedDashboardLogsDetailTitle= "SelectedDashboardLogsDetailTitle";
export const SelectedDashboardLogsDetailRefLabel= "SelectedDashboardLogsDetailRefLabel";
export const SelectedDashboardLogsDetailQuantityLabel= "SelectedDashboardLogsDetailQuantityLabel";