export const FormatMoneySouthAfrica = (num:number, div = " ") => {
    //num = parseFloat(num.toFixed(2))
    let strResult = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div);
    let arr = strResult.split(".");
    if(arr.length===1){
        strResult = strResult+".00";
    }else{
        let last = arr[1];
        if(last.length===1){
            strResult = strResult+"0";
        }
    }
    return strResult;

}
export const FormatMoneySouthAfrica2 = (num:number, div = " ") => {
    let strResult = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div);
    return strResult;

}

/*
export function formatMoney(number:number, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? " " : thouSep; // use the "," instead
    let sign = number < 0 ? "-" : "";
    let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    let j = 0
    j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}*/
