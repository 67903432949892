import {ConfigData, WcLogChipping} from "../types/data-types-woodchipping";
import {PostToPMIS} from "./service-http-post";
import {FetchDataConfig, PmisResponseType} from "./service-global";
import {LoginType} from "../types/general-types";


export const LoadDataConfig = async (org:string,username:string,setDataConfig:any) => {
    await FetchDataConfig(org, username, (res: any) => {
        console.log("loadDataConfig result> ", res, " !!=> ", username)
        let data = res as ConfigData
        setDataConfig(data)
    })
}
export const GetCompanyList = (DataConfig: ConfigData,org:string,role:string): any[] => {
    let ls: {value:any,label:string}[] = [];
    for (let i in DataConfig.Companies) {
        let row = DataConfig.Companies[i]
        if (role !== "super") {
            if(row.Code!==org){
                continue
            }
        }
        ls.push({label:row.Name,value:row.Code})
    }
    console.log("UUUUCompany list> ",ls)
    return ls
}
export const GetProjectList2 = (DataConfig: ConfigData,org:string,InputClient:string,): any[] => {
    let ls: {value:any,label:string}[] = [];
    for (let i in DataConfig.Projects) {
        let row = DataConfig.Projects[i]
        if (row.Org !== org) {
            continue
        }
        if(row.Client!==InputClient){
            continue
        }
        ls.push({label:row.Name,value:row.Name})
    }
    return ls
}
export const GetProjectList = (DataConfig: ConfigData,org:string,InputClient:string,): string[] => {
    let ls: string[] = [];
    for (let i in DataConfig.Projects) {
        let row = DataConfig.Projects[i]
        if (row.Org !== org) {
            continue
        }
        if(row.Client!==InputClient){
            continue
        }
        ls.push(row.Name)
    }
    return ls
}
export const GetClientList=(DataConfig: ConfigData,org:string):string[]=>{
    let ls: string[] = [];
    for (let i in DataConfig.Clients) {
        let row = DataConfig.Clients[i]
        if (row.Org === org) {
            ls.push(row.Name)
        }
    }
    return ls
}
export const GetClientList2=(DataConfig: ConfigData,org:string):any[]=>{
    let ls: {value:any,label:string}[] = [];
    for (let i in DataConfig.Clients) {
        let row = DataConfig.Clients[i]
        if (row.Org === org) {
            ls.push({label:row.Name,value:row.Name})
        }
    }
    return ls
}

export const DeleteChippingLog=async (action:string,table:string,record:WcLogChipping,feedback:any)=>{
    let endpoint=`/data/${action}/${table}`
    let data:any={}
    data.Org = record.Org;
    if(table!=="sale"){
        data.Record = record
    }else{
        data.RecordSale = record
    }
    console.log(":)DeleteChippingLog  send> ",data)
    await PostToPMIS(data,endpoint,(response:any)=>{
        console.log(":)DeleteChippingLog  response> ",response)
        let info :PmisResponseType = response as PmisResponseType
        feedback(info.RESULT)
    })

}