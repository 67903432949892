import {
    ClientType,
    CompanyType,
    ItemType,
    LoginType,
    MiniDashInfoResult,
    MiniDashInfoType,
    SiteInfo, SiteType,
    VehicleType
} from "./general-types";
import {ConfigData, MinDashboard, WcLogChipping, WcLogSale} from "./data-types-woodchipping";





export const initialWcLogSale:WcLogSale={
    AppName: "",
    Org: "",
    Client: "",
    Project: "",

    DeliveryNote: "",
    CubicSize: 0,
    Quantity: 0,
    DeliveryDate: "",
    DeliveryTime: "",
    Vehicle: "",
    Driver: "",

    Date: "",
    Time: "",
    OrgDateTime:""
}
export const initialWcLogChipping:WcLogChipping={
    AppName: "",
    Org: "",
    Client: "",
    Project: "",

    WbRef: "",
    CubicSize: 0,
    LoadMass: 0,
    Quantity: 0,
    DeliveryDate: "",
    DeliveryTime: "",
    Vehicle: "",
    Driver: "",

    Date: "",
    Time: "",
    OrgDateTime:""
}
export const initialMinDashboard:MinDashboard={
    Daily:{LoadMass:0,Quantity:0,Logs:[],},
    Monthly:{LoadMass:0,Quantity:0,Logs:[],},
    DailySale  : {Logs:[],CubicSize:0,Quantity:0},
    MonthlySale : {Logs:[],CubicSize:0,Quantity:0},
}
export const initialConfigData:ConfigData={
    Clients:[],
    Companies:[],
    Drivers:[],
    Projects:[],
    Vehicles:[]
}
export const initialSiteType:SiteType={

        org:"",
        client:"",
        item:"",
        site:"",
        quantity:0,
        rate:0,
        suburb:"",

}
export const initialMiniDashInfoType:MiniDashInfoType={
    PlannedSite:0,
    ActualSite:0,
    PlannedLogs:0,
    ActualLogs:0,
    MissingLogs:0,
    Percentage:0,
}
export const initialLoginType:LoginType = {
    Org     :"",
    Username :"",
    Password :"",
    Name     :"",
    Phone    :"",
    Email   : "",
    Role    : "",
}

export const initialMiniDashInfoResult:MiniDashInfoResult={
    Record:initialMiniDashInfoType,
    SiteData:[],
}

export const initialVehicleType:VehicleType={
    org:"",
    regnumber:"",
    "brand":"",
    "color":"",
    "date": "",
    "id": "",
    "mark": "",
    "mileage": 0,
    "model": "",
}

export const initialCompanyType:CompanyType = {
    CustomerNumber: "",
    Name: "",
}
export const initialClientType:ClientType={
    Org:"",
    Name:"",
}
export const initialItemType:ItemType={
    Org:"",
    Name:"",
    Client:"",

}