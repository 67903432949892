import Colors from "../../../constants/Colors";
import React from "react";
import styled from "styled-components";


type Props = {
    Infos: { label: string, value: string }[],
}
export default (props: Props) => {
    return (
        <Container>
            {props.Infos.map(row => {
                return (
                    <ContainerRow>
                        <Label >{row.label}:</Label>
                        <Label>{row.value}</Label>
                    </ContainerRow>
                )
            })}
        </Container>
    )
}

const Label = styled.label`
  color: ${Colors.brand.blue}
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
`
const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-style: solid;
  border-color: ${Colors.brand.lightgray};
  border-radius: 5px;
  height: 25px;
  border-width: 1px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
`
const rowStyle: React.CSSProperties = {
        flexDirection: "row",
        justifyContent: "space-between",
        borderStyle: "solid",
        borderColor: Colors.brand.lightgray,
        borderRadius: 5,
        height: 25,
        borderWidth: 1,
        marginTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        width: "100%",

    },
    value: React.CSSProperties = {
        fontWeight: "bold"
    },
    label: React.CSSProperties = {
        color: Colors.brand.blue
    };