
import Colors from "../../../constants/Colors";
import React from "react";


type Props={
    title:string,
    color?:string,
    fontSize?:number,
    fontWeight?:any,
    textAlign?:any,
}
export default (props:Props)=>{

       const  title: React.CSSProperties = {
            textAlign:props.textAlign?props.textAlign: "center",
            color: props.color? props.color:Colors.brand.lightRed,
            fontSize: props.fontSize?props.fontSize:14,
            fontWeight: props.fontWeight?props.fontWeight:"bold",
        };

    return(
        <label style={title}>{props.title}</label>
    )
}
