import Page from "../Page";
import Colors from "../../constants/Colors";
import CircleContainer from "../../compoents/common/CircleContainer";
import PageTitle from "../../compoents/common/PageTitle";
import React, {useState} from "react";
import PageContainer from "../../compoents/common/PageContainer";
import InputLabelForm from "../../compoents/common/InputLabelForm";
import ErrorMessageLabel from "../../compoents/common/ErrorMessageLabel";
import CircleActionButton from "../../compoents/common/CircleActionButton";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {cleanUsername, goTo} from "../../services/service-global";
import {LoginUser, LoginUserServicePmis} from "../../services/service-auth";
import {LoginResponseType, LoginType} from "../../types/general-types";
import {setLoginState} from "../../redux/actions";
import {SaveCookiesJson} from "../../services/service-cookies";
import {CmisWcAuthRecord} from "../../constants/common";
import {SetStorageRecord} from "../../services/service-locastorage";

export default ()=>{
    const [busy,setBusy]=useState(false)
    const [LoginError,setLoginError]=useState(false)
    const [LoginErrorMessage,setLoginErrorMessage]=useState("")
    const[InputUsername,setInputUsername]=useState("");
    const[InputPassword,setInputPassword]=useState("")

    const dispatch = useDispatch();

    const onClickSubmit=()=>{
        /**
         * Validation controller
         */

        console.info(`login issue, Username: ${InputUsername}\nPassword: ${InputPassword}`)
        if(InputUsername===""){
            alert("Sorry login input username requested!");
            return;
        }
        if(InputPassword===""){
            alert("Sorry login input password requested!");
            return;
        }

        onSubmit().then(null)

    }

    const onSubmit=async ()=>{
        let postData={
            Username       :cleanUsername(InputUsername),
            Password    :InputPassword.trim(),
        }
        await LoginUserServicePmis(postData,(response:any)=> {
            let info : LoginResponseType = response as LoginResponseType
            console.log("submit reg> ", response)
            if(!info.RESULT.status){
                setLoginErrorMessage(response["error"])
                setLoginError(true)
                return
            }
            let record = info.RESULT.record as LoginType
            dispatch(setLoginState(record))
            SaveCookiesJson(CmisWcAuthRecord,record);


            let fullName=record.Name;
            let email=record.Username;
            let phone=record.Phone;
            let role=record.Role;
            let org=record.Org;
            /**
             * let save to local storage our login information
             * use the code bellow
             */
            SetStorageRecord("phone",phone)
            SetStorageRecord("fullName",fullName)
            SetStorageRecord("role",role)
            SetStorageRecord("email",email)
            SetStorageRecord("org",org)
            SetStorageRecord("record",JSON.stringify(record))
            /**
             * end of saving
             */


            alert(`Welcome,  valid login \n${record.Name}`)

            goTo("/src-switch-company")
        })
    }

    return(
        <Page noBottom={false}>
            <PageContainer bgColor={Colors.brand.blue}>
                <CircleContainer
                    bgColor={Colors.brand.white}
                    labelColor={Colors.brand.red}
                />
                <PageTitle
                    title={"Login Service"}
                    color={Colors.brand.white}
                />

                <FormLogin>
                    <InputLabelForm
                        label={"Username"}
                        onChange={setInputUsername}
                        labelColor={Colors.brand.white}
                    />
                    <InputLabelForm
                        label={"Password"}
                        onChange={setInputPassword}
                        type={"password"}
                        labelColor={Colors.brand.white}
                    />

                    {LoginError&&(
                        // eslint-disable-next-line react/jsx-no-undef
                        <ErrorMessageLabel
                            message={LoginErrorMessage}
                            color={Colors.brand.red}
                            fontSize={18}
                        />
                    )}

                    <ContainerAction>
                        <CircleActionButton
                            bgColor={Colors.brand.gray}
                            title={"Submit"}
                            labelColor={Colors.brand.white}
                            onClick={()=>onClickSubmit()}
                            height={40}

                            radius={20}
                            noSign={true}
                        />
                    </ContainerAction>
                </FormLogin>

            </PageContainer>
        </Page>
    )
}

const ContainerAction =styled.div`
    display: flex;
    flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 100px;
  margin-top: 10px;
`
const FormLogin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`