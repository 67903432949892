import React from "react";
import styled from "styled-components";
import {GetDimensions} from "../../services/service-global";

const {width, height} = GetDimensions()
interface Props{
    children?: React.ReactNode;
    bgColor:string,
    flexDirection?:string,
    justifyContent?:string,
    alignItems?:string,
}

export default (props:Props)=>{
    return(
        <Container
            bgColor={props.bgColor}
            justifyContent={props.justifyContent||"center"}
            flexDirection={props.flexDirection||"center"}
            alignItems={props.alignItems||"center"}
        >
            {props.children}
        </Container>
    )
}

const Container = styled.div<{
    bgColor:string,
    flexDirection:string,
    justifyContent:string,
    alignItems:string,
}>`
  background-color: ${(props) =>props.bgColor};
  width: ${width - 20}px;
  min-height: ${ height + "px"};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 768px;
  overflow-y: auto;
  justify-content:${(props)=>props.justifyContent!=="center"? "flex-start":"center"};
  align-items: center;
`