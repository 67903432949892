import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import {OptionEntry} from "../../../types/general-types";

type Props={
    handleInput:any,
    data:OptionEntry[],
    label:string,
    SelectedValue:string,
}
export default (props:Props) => {
    let {
        handleInput,
        data,
        label,
        SelectedValue,
    } = props;
    const classes = useStyles();

    return (
        <FormControl className={classes.margin} style={{minWidth: "100%"}}>
            <InputLabel htmlFor="demo-customized-select-native">{label}</InputLabel>
            <NativeSelect
                name={label}
                id="demo-customized-select-native"
                value={SelectedValue}
                onChange={(e) => handleInput( e.target.value)}
                input={<BootstrapInput/>}
            >
                <option value="">Choose {label}...</option>
                {data.map((item) => {
                    return (
                        <option value={item.key}>{item.label}</option>
                    )
                })}

            </NativeSelect>
        </FormControl>
    )
}

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(0),
    },
}));
