import Colors from "../../constants/Colors";
import Page from "../Page";
import PageContainer from "../../compoents/common/PageContainer";
import PageTitle from "../../compoents/common/PageTitle";
import React, {useEffect, useState} from "react";

import styled from "styled-components";
import TopBar2 from "../../compoents/common/TopBar2";
import {useSelector} from "react-redux";
import {ConfigData} from "../../types/data-types-woodchipping";
import {initialConfigData, initialMiniDashInfoResult, initialMiniDashInfoType} from "../../types/initialize-types";
import {GetClientList2, GetCompanyList, GetProjectList2, LoadDataConfig} from "../../services/service-chipping-logs";
import {GetCurrentLoginState} from "../../services/service-auth";
import ButtonListWithKeyValue from "../../compoents/common/ButtonListWithKeyValue";
import NavBarBackCancel from "../../compoents/common/NavBarBackCancel";
import {FetchDataConfigWithAllProject, GetDimensions} from "../../services/service-global";
import ButtonCircle from "../../compoents/common/ServiceLoggingDashboard/ButtonCircle";
import InfosSelection from "../../compoents/common/ServiceLoggingDashboard/InfosSelection";
import InfoSites from "../../compoents/common/ServiceLoggingDashboard/InfoSites";
import moment from "moment";
import PageSubTitle from "../../compoents/common/ServiceLoggingDashboard/PageSubTitle";
import {MiniDashInfoResult, MiniDashInfoType, SiteType, VehicleType} from "../../types/general-types";
import InfoLogs3Dash from "../../compoents/common/ServiceLoggingDashboard/InfoLogs3Dash";
import {FetchMiniDash, FetchSites, FetchVehicle, ProcessSubmitLog} from "../../services/serviceGlobalDataSrcLogging";
import NewLogStepChooseSite from "../../compoents/common/ServiceLoggingDashboard/NewLogStep1ChooseSite";
import NewLogStep2DateQuantity from "../../compoents/common/ServiceLoggingDashboard/NewLogStep2DateQuantity";

const {width, height} = GetDimensions()
export default () => {
    const state = useSelector((state: any) => state.core);
    const [Step, setStep] = useState(1)
    const [IsSync, setIsSync] = useState(false)
    const [DataConfig, setDataConfig] = useState<ConfigData>(initialConfigData)
    const [SelectedItem, setSelectedItem] = useState("none")
    const [SelectedCompany, setSelectedCompany] = useState("none")
    const [SelectedClient, setSelectedClient] = useState("none")
    const [SelectedSite, setSelectedSite] = useState("")
    const [LoginState, setLoginState] = useState(GetCurrentLoginState())

    const [DashInfoMonthly, setDashInfoMonthly] = useState<MiniDashInfoResult>(initialMiniDashInfoResult)
    const [DashInfoDaily, setDashInfoDaily] = useState<MiniDashInfoType>(initialMiniDashInfoType)
    const [DataSites, setDataSites] = useState<SiteType[]>([])

    const [SelectedVehicle, setSelectedVehicle] = useState("");
    const [DataVehicle, setDataVehicle] = useState<VehicleType[]>([]);
    const [InputDeliveryNote, setInputDeliveryNote] = useState("");
    const [InputDeliveryDate, setInputDeliveryDate] = useState("");
    const [InputUnits, setInputUnits] = useState("");
    const [SelectedTargetPlannedSite, setSelectedTargetPlannedSite] = useState(0)

    useEffect(() => {
        if (!IsSync) {
            FetchDataConfigWithAllProject(LoginState.Org, LoginState.Username, setDataConfig).then(null)
            setIsSync(true)
        }
    })

    useEffect(() => {
        if (Step === 4) {
            loadMonthlyMiniDash().then(null)
            loadDailyMiniDash().then(null)
            loadSites().then(null)
            loadVehicles().then(null)
        }
    }, [Step])
    const getInfoLogsMonth = (): { label: string, value: any, labelColor: string, bgColor: string }[] => {
        let dash = DashInfoMonthly
        if (!dash.Record) {
            dash.Record = initialMiniDashInfoType
        }
        return [
            {
                label: "Planned Logs",
                value: dash.Record.PlannedLogs || 0,
                bgColor: Colors.brand.blue,
                labelColor: Colors.brand.blue,
            },
            {
                label: "Actual Logs",
                value: dash.Record.ActualLogs || 0,
                bgColor: Colors.brand.blue,
                labelColor: Colors.brand.blue,
            },
            {
                label: "Missing Logs",
                value: dash.Record.MissingLogs || 0,
                bgColor: Colors.brand.blue,
                labelColor: Colors.brand.blue,
            },
        ]
    }
    const loadSites = async () => {
        let company = SelectedCompany
        let client = SelectedClient;
        let project = SelectedItem;
        await FetchSites(company, client, project, (res: any) => {
            let sites: SiteType[] = res as SiteType[]
            console.log("///Sites > ", res)
            let infoData: { [index: string]: SiteType } = {}
            let ls: SiteType[] = []
            for (let i in sites) {
                let row = res[i]

                if (row.org !== company) {
                    continue
                }
                if (row.client !== client) {
                    continue
                }
                if (row.item !== project) {
                    continue
                }
                infoData[row.site] = row
            }
            for (let i in infoData) {
                let row = infoData[i]
                ls.push(row)
            }
            setDataSites(ls)

        })
    }
    const loadDailyMiniDash = async () => {
        let company = SelectedCompany
        let client = SelectedClient;
        let item = SelectedItem;
        await FetchMiniDash({
            org: company,
            client,
            item,
            targetSite: "",
            periodType: "daily",
        }, (res: any) => {
            let infoOut = res as MiniDashInfoType
            setDashInfoDaily(infoOut)
        })
    }
    const getActualTotalSite = (): number => {
        let num = 0;
        for (let i in DashInfoMonthly.SiteData) {
            let row = DashInfoMonthly.SiteData[i];
            if (row.TotalActual > 0) {
                num++
            }
        }
        return num
    }
    const loadMonthlyMiniDash = async () => {
        let company = SelectedCompany
        let client = SelectedClient;
        let item = SelectedItem;
        await FetchMiniDash({
            org: company,
            client,
            item,
            targetSite: "",
            periodType: "monthly",
        }, (res: any) => {
            let infoOut = res as MiniDashInfoResult
            setDashInfoMonthly(infoOut)
        })
    }

    const loadVehicles = async () => {
        let company = SelectedCompany
        await FetchVehicle(company, (res: VehicleType[]) => {
            setDataVehicle(res)
        })
    }

    const getDashMiniDaily = (): { label: string, value: any }[] => {
        return [
            {label: "Planned", value: DashInfoDaily.PlannedLogs || 0},
            {label: "Actual", value: DashInfoDaily.ActualLogs || 0},
            {label: "Outstanding", value: DashInfoDaily.MissingLogs || 0},
        ]
    }
    const onSelectCompany = (item: string) => {
        setSelectedCompany(item)
        setStep(2)
    }
    const getInfoSelectionList = (): { label: string, value: string }[] => {
        return [
            {label: "Company", value: SelectedCompany},
            {label: "Customer", value: SelectedClient},
            {label: "Service Type", value: SelectedItem},
        ]
    }
    const onSelectClient = (item: string) => {
        setSelectedClient(item)
        //set your next step
        setStep(3)
    }
    const onSelectItem = (item: string) => {
        setSelectedItem(item)
        setStep(4)
    }

    const onBack = () => {
        const st = Step - 1
        setStep(st)
    }
    const onCancelStep = () => {
        setStep(1)
    }
    const onNewSite = () => {
        setStep(5)
    }
    const onChooseNewSite = (site: SiteType) => {
        setSelectedSite(site.site)
        setSelectedTargetPlannedSite(site.quantity)
        setStep(6)
    }
    const validationInputBeforeSubmit = (): boolean => {
        let client = SelectedClient;
        let item = SelectedItem;

        if (client === "") {
            alert("fail, Sorry please select your client!")
            return false
        }
        if (item === "") {
            alert("fail, Sorry please select your service type!")
            return false
        }
        if (SelectedSite === "") {
            alert("fail, Sorry please select your site!")
            return false
        }
        if (SelectedVehicle === "") {
            alert("fail, Sorry please select your vehicle!")
            return false
        }
        if (InputDeliveryDate === "") {
            alert("fail, Sorry please select your service date!")
            return false
        }
        if (InputUnits === "") {
            alert("fail, Sorry please provide number of service units!")
            return false
        }
        if (InputDeliveryNote === "") {
            alert("fail, Sorry please provide delivery note number!")
            return false
        }

        let numUnits = parseInt(InputUnits);
        if (numUnits > SelectedTargetPlannedSite) {
            alert(`WARNING!
            \nThe number of units provided is GREATER than the schedule.
            \nRe-check and input correct units.`)
            return false
        }

        return true
    }
    const onSubmitNewLog = async () => {

        if(!validationInputBeforeSubmit()){
            return
        }
        if(!window.confirm("Are you sure to submit this log?")){
            return
        }
        let org = SelectedCompany
        let client = SelectedClient;
        let item = SelectedItem;
        let targetDate = InputDeliveryDate;

        let record={
            org,
            client,
            item,
            targetDate,
            position:1,
            site:SelectedSite,
            vehicle:SelectedVehicle,
            units:parseInt(InputUnits),
            note:InputDeliveryNote,
        }
        let records:any[]=[];
        records.push(record)
        let postData = {
            Infos: records,
        }

        console.log("postData send > ",postData)
        await ProcessSubmitLog(postData,(res:any)=>{
            console.log("postData out > ",res)
            alert("Thank you record submitted!")
            setStep(4)
            loadMonthlyMiniDash().then(null)
            loadMonthlyMiniDash().then(null)
        })


    }

    const RenderStep6OtherInfo = () => {
        return (
            <>
                <ContainerTitle>
                    <NavBarBackCancel onBack={onBack} onCancel={onCancelStep}/>
                </ContainerTitle>
                <PageSubTitle
                    title={"New log, additional info"}
                    fontSize={16}
                />
                <NewLogStep2DateQuantity
                    setStep={setStep}
                    onSubmit={onSubmitNewLog}
                    DataVehicle={DataVehicle}
                    SelectedVehicle={SelectedVehicle}
                    inputDeliveryNote={InputDeliveryNote}
                    onChangeDeliveryNote={setInputDeliveryNote}
                    onChangeDate={setInputDeliveryDate}
                    inputUnits={InputUnits}
                    onChangeUnits={setInputUnits}
                    onChangeVehicle={setSelectedVehicle}
                    SelectedSite={SelectedSite}
                    TargetPlannedSite={SelectedTargetPlannedSite}
                />
            </>
        )
    }
    const RenderStep5SelectSite = () => {
        return (
            <>
                <ContainerTitle>
                    <NavBarBackCancel onBack={onBack} onCancel={onCancelStep}/>
                </ContainerTitle>
                <PageSubTitle
                    title={"New log, please select site"}
                    fontSize={16}
                />
                <NewLogStepChooseSite
                    onSelect={onChooseNewSite}
                    SelectedItem={SelectedSite}
                    DataSites={DataSites}
                    SelectedClient={SelectedClient}
                    SelectedCompany={SelectedCompany}
                    setStep={onChooseNewSite}
                />
            </>
        )
    }
    const RenderStep4Dashboard = () => {
        return (

            <>
                <ContainerTitle>
                    <NavBarBackCancel onBack={onBack} onCancel={onCancelStep}/>
                </ContainerTitle>
                <InfosSelection
                    Infos={getInfoSelectionList()}
                />
                <SectionBox>
                    <SectionBoxInner>
                        <PageSubTitle
                            title={"Month Services (" + moment().format("MMM-YYYY") + ")"}
                            fontSize={16}
                        />
                        <InfoSites
                            Planned={DataSites.length}
                            Serviced={getActualTotalSite()}
                        />

                        <InfoLogs3Dash Infos={getInfoLogsMonth()}/>
                    </SectionBoxInner>
                </SectionBox>


                <PageSubTitle
                    title={"Daily Service (" + moment().format("ddd DD-MMM-YYYY") + ")"}
                    fontSize={16}
                />

                <InfosSelection
                    Infos={getDashMiniDaily()}
                />

                <ButtonCircle
                    onPress={() => onNewSite()}
                    title={"add"}
                    backgroundColor={Colors.brand.green}
                />
            </>
        )
    }
    const RenderStep3 = () => {
        return (
            <>
                <ContainerTitle>
                    <NavBarBackCancel onBack={onBack} onCancel={onCancelStep}/>
                    <PageTitle
                        title={" Select your project or item  below"}
                        color={Colors.brand.blue}
                    />
                </ContainerTitle>
                <ButtonListWithKeyValue
                    options={GetProjectList2(DataConfig, SelectedCompany, SelectedClient)}
                    onPress={onSelectItem}
                    selectedItem={SelectedItem}
                />
            </>
        )
    }
    const RenderStep2 = () => {
        return (
            <>

                <ContainerTitle>
                    <NavBarBackCancel onBack={onBack} onCancel={onCancelStep}/>
                    <PageTitle
                        title={" Select your client  below"}
                        color={Colors.brand.blue}
                    />
                </ContainerTitle>
                <ButtonListWithKeyValue
                    options={GetClientList2(DataConfig, SelectedCompany)}
                    onPress={onSelectClient}
                    selectedItem={SelectedClient}
                />
            </>
        )
    }
    const RenderStep1 = () => {
        return (
            <>
                <ContainerTitle>
                    <PageTitle
                        title={"Before continue choose your Company from the list below"}
                        color={Colors.brand.blue}
                    />
                </ContainerTitle>

                <ButtonListWithKeyValue
                    options={GetCompanyList(DataConfig, LoginState.Org, LoginState.Role)}
                    onPress={onSelectCompany}
                    selectedItem={SelectedCompany}
                />
            </>
        )
    }
    const SwitchView = () => {
        switch (Step) {
            case 1:
                return RenderStep1()
            case 2:
                return RenderStep2()
            case 3:
                return RenderStep3()
            case 4:
                return RenderStep4Dashboard()
            case 5:
                return RenderStep5SelectSite()
            case 6 :
                return RenderStep6OtherInfo()
            default:
                return RenderStep1()
        }
    }
    return (
        <Page noMenu={false}>
            <PageContainer
                bgColor={Colors.brand.white}
                justifyContent={"flex-start"}
                alignItems={"top"}
            >
                <TopBar2 title={"Service Logging"}/>

                {SwitchView()}

            </PageContainer>
        </Page>
    )
}

const ContainerTitle = styled.div`
  padding: 20px;
  width: 90%;
`

const SectionBox = styled.div`
  min-height: 240px;
  background-color: ${Colors.brand.blue};
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 5px;
`
const SectionBoxInner = styled.div`
  min-height: 160px;
  width: 95%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
`
const styles = {
    sectionBox: {
        minHeight: 240,
        width: width,
        backgroundColor: Colors.brand.blue,
        paddingTop: 20,
        paddingLeft: 10,
        paddingRight: 10,
    },
    sectionBoxInner: {
        minHeight: 160,
        width: width - 20,
        backgroundColor: "white",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 10
    }
}