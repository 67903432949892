import React, {useState} from "react";
import Page from "./Page";
import styled from "styled-components";
import Colors from "../constants/Colors";
import {GetDimensions, goTo} from "../services/service-global";
import PmisLogo1 from "../assets/images/PmisLogo2.png"
import CopyRightBottom from "../compoents/common/CopyRightBottom";

const {width, height} = GetDimensions()

export default (props: any) => {

    const clickStartWoodChipping = () => {
        //alert("Let start")
        goTo("/login")
        // window.location.href = "/login"
    }
    const clickStartService= () => {
        //alert("Let start")
        goTo("/src-login")
        // window.location.href = "/login"
    }
    return (
        <Page>
            <Container>

                <ContainerLogo src={PmisLogo1}>

                </ContainerLogo>
                <BlankRow />

                <ContainerLabel2>
                    <Label2>DELIVERY NOTE LOGGING SYSTEM</Label2>
                </ContainerLabel2>

                <ContainerLabel2>
                    <Label3>Welcome to the CMIS Delivery Note Logging Application.</Label3>
                </ContainerLabel2>

                <ContainerLabel2>
                    <Label3>
                        This app is designed to capture service delivery notes on a daily basis and transmit these to the CMIS back-end.</Label3>
                </ContainerLabel2>

                <ContainerLabel2>
                    <Label3>The primary purpose of the app is to ensure that the organisation captures <BoldMe>ALL</BoldMe> services performed, <BoldMe>ACCURATLEY</BoldMe> and <BoldMe>TIMEOUSLY EVERY DAY!</BoldMe></Label3>
                </ContainerLabel2>

                <ContainerLabel2>
                    <Label3>It is therefore <BoldMe>CRITICAL</BoldMe> that <BoldMe>EVERY DELIVERY NOTE</BoldMe> is logged / captured <BoldMe>EVERY DAY</BoldMe>.</Label3>
                </ContainerLabel2>


                <ContainerJustifySpaceBetween>
                    <ContainerBtn onClick={clickStartService} bg={Colors.brand.green}>
                        <Label5>Planned Services</Label5>
                    </ContainerBtn>
                    <ContainerBtn onClick={clickStartWoodChipping} bg={Colors.brand.green}>
                        <Label5>Wood Chipping</Label5>
                    </ContainerBtn>
                </ContainerJustifySpaceBetween>


                <CopyRightBottom />
            </Container>

        </Page>
    )
}

const BoldMe=styled.span`
    font-weight: bold;
`
const BlankRow = styled.div`
  margin-top: 50px;
`
const ContainerJustifySpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 50px;
`
const ContainerLogo = styled.img`
  width: 80%;

`
const Label5 = styled.label`
  text-align: center;
  color: ${Colors.brand.white};
  font-weight: bold;
  font-size: 18px;
`
const ContainerBtn = styled.div<{ bg: string }>`
  width: 80%;
  height: 65px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${(props)=>props.bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`
const ContainerLabel2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  padding: 10px;
  
`


const Label2 = styled.label`
  font-weight: bold;
  font-size: 22px;
  font-style: normal;
  color: ${Colors.brand.white};
`
const Label3 = styled.label`
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  color: ${Colors.brand.white};
  
`

const Container = styled.div`
  background-color: ${Colors.brand.blue};
  width: ${width - 20}px;
  min-height: ${(props) => height + "px"};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 768px;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
`
